<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Inspections</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="col-xl-12">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-4">
              <h3 class="mb-0" id="new_inspection_heading">Create New Inspection</h3>
            </div>
          </div>
          <new-inspection-form
            :lead="lead" 
            :enquiry="lastEnquiry"
            :shortlists="shortlists"
            @submitRequest="submitRequest"/>
        </card>
      </div>
    </div>

    <modal
      :show.sync="
        modalConfirmation"
      body-classes="p-0"
      modal-classes="modal-dialog-centered modal-sm"
    >
      <card
        type="secondary"
        shadowheader-classes="bg-white pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0"
      >
        <template>
          <div class="text-center mb-3">
            <h5 class="modal-title" id="exampleModalLabel">
              Sending Inspection Request
            </h5>
          </div>
        </template>
        <template>
          <div class="text-center container-btn">
            <div class="mt-3 mb-3" v-loading="loading"></div>
            <span v-if="!loading">
              <base-button type="secondary" @click="completeClose()" id="return_to_inspections_btn">
                Return to Lead Details
              </base-button>
            </span>
          </div>
        </template>
      </card>
    </modal>
  </div>
</template>


<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import NewInspectionFrom from "@/views/Widgets/Inspections/NewInspectionForm";
import Fuse from "fuse.js";
import moment from 'moment';
// import { mapState } from 'vuex'

export default {
  components: {
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [NewInspectionFrom.name]: NewInspectionFrom,
  },
  data() {
    return {
      leadId: parseInt(this.$route.params.id),
      leadEventId: parseInt(this.$route.params.eventId),
      modalConfirmation: false,
      loading: true,
    };
  },
  async created() {
    await this.$store.dispatch("leads/getSingle", { id: this.leadId });
    await this.$store.dispatch("shortlist/getShortlist", this.leadEventId);
    await this.$store.dispatch("leadEvent/getEnquiries", this.leadEventId);
    this.loading = false;
  },
  computed: {
    lead() {
      return this.$store.getters["leads/getLeadById"](this.leadId);
    },
    lastEnquiry() {
      let enquiries = this.$store.getters["leadEvent/getEnquiriesByLeadEventId"](this.leadEventId);
      return enquiries ? (enquiries[enquiries.length - 1]) : {};
    },
    shortlists() {
      return this.$store.getters["shortlist/getShortListByLeadEventId"](this.leadEventId);
    },
  },
  methods: {
    submitRequest(formData) {
      this.loading = true;
      this.$store
        .dispatch("inspections/addInspectionRequest", {
          leadEventId: this.leadEventId,
          formData: formData,
        })
        .then(() => {
          this.modalConfirmation = true;
          this.loading = false;
        });
    },
    completeClose() {
      this.modalConfirmation = false;
      this.$router.push({ name: 'LeadDetails', params: {id: this.leadId} });
    },
  },
};
</script>
<style lang="scss">
.no-border-card .card-footer {
  border-top: 0;
}

.inspection-request {
  .availability {
    padding-left: 1.75rem;
    .time {
      min-width: 50px;
      display: table-cell;
    }
  }
}

.container-btn {
  margin-top: 40px;
}
</style>